import {combineReducers} from 'redux'
import {
	users, universities, universityAlbums, universityTrending, policyTerms, collections,
	tariffModels, courses, calculations, majors, goalsCaches, articles, promoCodes, generalGroups,
	coefficientCoursesGroups, studyYears, relationTypes, degreeGroups, concentrationGroups, seoPages, reviews, goals,
	sportTeams
} from '@prepler/core/lib/reducers/admin'


const adminReducer = combineReducers({
	users,
	universities,
	universityAlbums,
	universityTrending,
	policyTerms,
	collections,
	tariffModels,
	courses,
	calculations,
	majors,
	goalsCaches,
	articles,
	promoCodes,
	generalGroups,
	coefficientCoursesGroups,
	studyYears,
	relationTypes,
	degreeGroups,
	concentrationGroups,
	seoPages,
	reviews,
	goals,
	sportTeams
});

export default adminReducer;
