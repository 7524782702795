import React from 'react'
import Loader from './loader'

const Loading = (props) => {
	if (props.isLoading) {
		if (props.timedOut) {
			return <div>Loader timed out!</div>;
		} else if (props.pastDelay) {
			return <Loader/>;
		} else {
			// Don't flash "Loading..." when we don't need to.
			return null;
		}
	} else if (props.error) {
		return <div>Error! Component failed to load</div>;
	}
	return null;
};
// Just return Loader since @loadable fallback should be component instance
export default <Loading isLoading={true} pastDelay={true}/>;
