import gql from 'graphql-tag'

const resolvers = {
	Mutation: {
		setCurrentPosition: (_root, {ready, lat, lon}, {cache, getCacheKey}) => {
			const id = getCacheKey({__typename: 'PositionInput', id: '42'})
			const fragment = gql`
					fragment latLon on PositionInput {
						ready
						lat
						lon
					}
				`;
			const position = cache.readFragment({ fragment, id });
			const data = { ...position, ready, lat, lon };
			cache.writeData({id, data});
			return null;
		},
	},
};

export default resolvers;
