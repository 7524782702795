import {combineReducers} from 'redux'
import {majors, courses, goals, periods, subtitutes, goalAccesses, studyYears, years} from '@prepler/core/lib/reducers/learning'

const learningReducer = combineReducers({
	majors,
	courses,
	goals,
	periods,
	subtitutes,
	goalAccesses,
	studyYears,
	years
});

export default learningReducer;
