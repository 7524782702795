import React, {useEffect} from 'react'
import {hydrate, render} from 'react-dom'
import {persistStore} from 'redux-persist'
import {Provider, useDispatch} from 'react-redux'
import {ApolloProvider} from '@apollo/react-hooks'
import {ThemeProvider, JssProvider} from 'react-jss'
import uuid from 'uuid/v4'
import {loadableReady} from '@loadable/component'

import App from '@prepler/core'
import {loadCurrentUserSuccess} from '@prepler/core/lib/actions/sessionActions'
import {loadProfileSuccess} from '@prepler/core/lib/actions/profileActions'
import {setDeviceId} from '@prepler/core/lib/actions/authActions'

import apolloClient, {setApolloDeviceId} from './apollo/client'
import theme from './theme'
import GlobalStyles from './globalStyles'
import {setStore, handleChangeStore} from './initializers'
import configureStore from './store/configureStore'
import useCurrentUser from './graphql/queries/useCurrentUser'
import Routes from './components/loadable/components/routes'
import jssGenerateId from './utils/jssGenerateId'
import {setDeviceId as setDeviceIdCookie, setDeviceIdExist} from './utils/cookies'
import * as serviceWorker from './serviceWorker'
import reportWebVitals from './reportWebVitals'

import 'typeface-handlee/index.css'
import './assets/stylesheets/ionicons.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'rc-slider/assets/index.css'
import 'react-tippy/dist/tippy.css'
import './assets/stylesheets/tippy-prepler-theme.css'

const store = configureStore();
setStore(store);
handleChangeStore(store);
if (App.type !== 'node') {
	loadableReady(() => {
		persistStore(store, {},
			() => {
				const root = document.getElementById('root');
				(window._static && window.location.pathname !== '/' ? render : hydrate)(<Application apolloClient={apolloClient}/>, root, () => {
					['ssr-styles', 'ssr-store-state', 'ssr-apollo-state'].forEach((id) => {
						const node = document.getElementById(id);
						node && node.parentNode.removeChild(node);
					});
					serviceWorker.unregister();
					// process.env.REACT_APP_SITE_URL ? serviceWorker.register() : serviceWorker.unregister();
				});
				App.env !== 'production' && reportWebVitals(console.log);
			}
		);
	});
}

export const CurrentUser = ({children}) => {
	useEffect(() => {
		const deviceId = store.getState().auth.deviceId || uuid();
		setDeviceId(deviceId)(store.dispatch);
		setApolloDeviceId(deviceId);
		setDeviceIdCookie(deviceId);
	}, []);

	const dispatch = useDispatch();
	const currentUser = useCurrentUser();

	const storeUser = () => {
		if (currentUser.legacyUser) {
			dispatch(loadCurrentUserSuccess(currentUser.legacyUser, {implicit: currentUser.implicit}));
		}
		if (currentUser.legacyProfile) {
			dispatch(loadProfileSuccess(currentUser.legacyProfile));
		}
		setDeviceIdExist(currentUser.implicit);
	};
	useEffect(storeUser, [currentUser]);
	App.type === 'node' && storeUser();
	return children;
};

const Application = ({store: storeParam, apolloClient, routerContext, routerLocation, sheets}) => {
	return (
		<JssProvider registry={sheets} generateId={jssGenerateId}>
			<ThemeProvider theme={theme}>
				<GlobalStyles>
					<ApolloProvider client={apolloClient}>
						<Provider store={storeParam || store}>
							<CurrentUser>
								<Routes {...{routerContext, routerLocation}}/>
							</CurrentUser>
						</Provider>
					</ApolloProvider>
				</GlobalStyles>
			</ThemeProvider>
		</JssProvider>
	);
};

export default Application;
