import injectSheet from 'react-jss'

const globalStyles = theme => ({
	'@global': {
		'*': {
			outline: 'none',
			boxSizing: 'border-box'
		},
		'html, body, body > div#root': {
			height: '100%'
		},
		'body': {
			margin: '0',
			padding: '0',
			color: '#555',
			font: theme.fonts.base,
			fontWeight: theme.fontWeight.normal,
			background: '#fff',
			'-webkit-font-smoothing': 'antialiased',
			'-moz-osx-font-smoothing': 'grayscale'
		},
		'input, .btn, .input-group-addon, .fb_reset': {
			fontWeight: theme.fontWeight.normal
		},
		'i': {
			fontStyle: 'normal'
		},
		'p': {
			margin: '0',
			padding: '6px 0'
		},
		'a': {
			display: 'block',
			color: theme.colors.primary,
			textDecoration: 'none',
			'&:hover': {
				color: theme.colors.primary
			},
			'img': {
				display: 'block',
				border: 'none'
			}
		},
		'h1, h2, h3, h4, h5, h6': {
			margin: '0',
			padding: '0',
			fontWeight: theme.fontWeight.normal,
			color: '#222'
		},
		'h2': {
			fontSize: '30px',
			lineHeight: '38px',
			textAlign: 'center'
		},
		'ul, ul li, menu': {
			margin: '0',
			padding: '0',
			listStyle: 'none'
		},
		'ol, ol li': {
			margin: '0',
			padding: '0',
			listStyle: 'decimal'
		},
		'label': {
			display: 'block',
			width: '100%',
			marginBottom: '15px',
			'& strong': {
				display: 'block',
				paddingBottom: '5px',
				fontWeight: theme.fontWeight.normal
			},
			'& span': {
				'&.error': {
					fontWeight: theme.fontWeight.normal,
					color: '#a94442'
				},
				'&.warning': {
					fontWeight: theme.fontWeight.normal,
					color: '#8a6d3b'
				},
			},
			'&.radio-inline': {
				width: 'auto',
				'&.disabled': {
					color: '#999'
				}
			},
		},
		'.btn-group label': {
			width: 'auto'
		},
		'.modal-content': {
			fontSize: '16px',
		},
		'span.required': {
			position: 'relative',
			color: theme.colors.red[2],
			fontSize: '20px',
			top: '6px'
		},
		'[role="button"]': {
			'-webkit-user-select': 'none',
			'-moz-user-select': 'none',
			userSelect: 'none'
		},
		'button, [role="button"], [type="submit"], [type="button"]': {
			cursor: 'pointer',
		},
		// bootstrap override
		'.pagination.no-margin': {
			margin: '0px',
			'& li': {
				'& a': {
					outline: 'none',
					padding: '6px 10px',
					'@media screen and (min-width: 992px)': {
						padding: '6px 6px',
					},
					'@media screen and (min-width: 1200px)': {
						padding: '6px 10px',
					},
				},
			},
		},
		'.ion-disabled': {
			color: '#999',
		},
		'.btn-success': {
			backgroundColor: '#35b135',
			'&:hover': {
				backgroundColor: '#2acb2a',
			},
		},
		'@media screen and (max-width: 409px)': {
			'.row': {
				marginRight: '-10px',
				marginLeft: '-10px',
			},
			'.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12': {
				paddingRight: '10px',
				paddingLeft: '10px',
			},
		},
	}
});

const GlobalStyles = ({children}) => children;

export default injectSheet(globalStyles)(GlobalStyles);
