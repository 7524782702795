export default {
	universities: {data: [], meta: {}},
	universityAlbums: {universities: [], meta: {}},
	universityTrending: {universities: [], meta: {}},
	policyTerms: {policyTerms: [], meta: {}},
	users: {users: [], meta: {}},
	collections: {},
	tariffModels: {data: [], meta: {}},
	courses: {data: [], meta: {}},
	calculations: {data: [], meta: {}},
	majors: {data: [], meta: {}},
	goalsCaches: {data: []},
	articles: {data: [], meta: {}},
	promoCodes: {data: [], meta: {}},
	generalGroups: {data: [], meta: {}},
	coefficientCoursesGroups: {data: [], meta: {}},
	studyYears: {data: [], meta: {}},
	relationTypes: {data: [], meta: {}},
	degreeGroups: {data: [], meta: {}},
	concentrationGroups: {data: [], meta: {}},
	seoPages: {pages: []},
	reviews: {data: [], meta: {}},
	goals: {data: [], meta: {}},
	sportTeams: {data: [], meta: {}}
}
