import ReactGA from 'react-ga'
import App from '@prepler/core'

if (App.type !== 'node' && process.env.REACT_APP_GOOGLE_TRACKING_ID) { // eslint-disable-line no-undef
	ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID); // eslint-disable-line no-undef
} else {
	ReactGA.initialize('fake', {testMode: true});
}

export const handleChangeUser = (user) => {
	ReactGA.set({userId: (user || {}).id});
};

export default ReactGA;
