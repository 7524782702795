// Remove after async reducers fix
import {reducer as form} from 'redux-form'

import rehydrate from '@prepler/core/lib/reducers/rehydrateReducer'
import entities from '@prepler/core/lib/reducers/entitiesReducer'
import auth from '@prepler/core/lib/reducers/authReducer'
import deviceStorage from '@prepler/core/lib/reducers/deviceStorageReducer'
import notifications from '@prepler/core/lib/reducers/notificationsReducer'
// import socialAuths from '@prepler/core/lib/reducers/socialAuthsReducer'
import profile from '@prepler/core/lib/reducers/profileReducer'
import locations from '@prepler/core/lib/reducers/locationsReducer'
import search from '@prepler/core/lib/reducers/searchReducer'
// import searchForm from '@prepler/core/lib/reducers/searchFormReducer'
import universities from '@prepler/core/lib/reducers/universitiesReducer'
import collections from '@prepler/core/lib/reducers/collectionsReducer'
import roles from '@prepler/core/lib/reducers/rolesReducer'
import users from '@prepler/core/lib/reducers/usersReducer'
import tariffModels from '@prepler/core/lib/reducers/tariffModelsReducer'
import reviews from '@prepler/core/lib/reducers/reviewsReducer'
// import chats from '@prepler/core/lib/reducers/chatsReducer'
// import messages from '@prepler/core/lib/reducers/messagesReducer'

import admin from './admin'
import learning from './learning'

const staticReducers = {
	form, // redux-form
	admin,
	learning,

	// Essential:
	rehydrate, // everywhere
	entities, // everywhere
	auth,
	profile, // PreferredSearch, ProfileAbout, ProfilePersonal, LoanCalculatorForm, MajorSelect
	deviceStorage, // REHYDRATE, CookiesPopup(SET_DEVICE_STORAGE)
	notifications, // everywhere

	// Candidates for deprecation (rewrite using graphql) or move to admin:
	// socialAuths, // deprecated, unused
	search, // SearchForm(admin)
	// searchForm, // deprecated, unused
	universities, // SearchForm(admin), UniversitySelect(GoalForm, Vote, admin), feeds: Twitter, Facebook
	// chats, // currently disabled due to firebase
	// messages, // currently disabled due to firebase
	collections, // CollectionHeader(currently temporary? unused)
	reviews, // UniversityReviews, UniversityReviewNew

	// Selects:
	locations, // LocationSelect
	roles, // RoleSelect(admin)
	tariffModels, // TariffModelSelect(admin)
	users, // Chat, Chats, UserSelect(admin)
};

export default staticReducers;
