import {createStore, applyMiddleware, compose, combineReducers} from 'redux'
import staticReducers from '../reducers'
import thunk from 'redux-thunk'
import App from '@prepler/core'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState = App.initialState) {
	const preloadedState = window.__STORE_STATE__;
	delete window.__STORE_STATE__;
	const store = createStore(
		createReducer(),
		preloadedState || initialState,
		composeEnhancers(applyMiddleware(thunk))
	);

	store.asyncReducers = {};

	store.injectReducer = (key, asyncReducer) => {
		store.asyncReducers[key] = asyncReducer;
		store.replaceReducer(createReducer(store.asyncReducers));
	};

	return store;
}

function createReducer(asyncReducers) {
	return combineReducers({
		...staticReducers,
		...asyncReducers
	})
}
