const apolloInitialState = {
	searchFormQuery: '',
	searchFormTitle: '',
	currentPosition: {
		// Apollo cache works with ID
		id: '42', ready: false, lat: null, lon: null,
		__typename: 'PositionInput'
	},
};

export default apolloInitialState;
