import React from 'react'
import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'

export const CURRENT_USER = gql`
	query CurrentUser {
		currentUser {
			id
			name
			authorized
			implicit
			roles
			universitiesCount
			legacyProfile
			legacyUser
		}
	}
`;

const dummyUser = {
	id: null,
	name: null,
	authorized: false,
	implicit: false,
	universitiesCount: 0,
	roles: [],
	legacyProfile: {},
	profile: {},
};

const useCurrentUser = () => {
	const {loading, error, data} = useQuery(CURRENT_USER, { ssr: !window._static });

	if (loading || error) return dummyUser;

	const {currentUser} = data;
	return {...dummyUser, ...currentUser};
};

export default useCurrentUser;
