import App from '@prepler/core'

let rollbarInstance = null;
if (App.type !== 'node') {
	const rollbar = require('rollbar-browser');
	const RollbarConfig = require('../config').RollbarConfig;

	rollbarInstance = rollbar.init({
		accessToken: RollbarConfig.accessToken,
		captureUncaught: true,
		enabled: !!RollbarConfig.accessToken,
		environment: App.env,
		payload: {environment: App.env, type: App.type}
	});
}

export const Rollbar = rollbarInstance;

window.Rollbar = Rollbar;

export const handleChangeUser = (user) => {
	Rollbar && Rollbar.configure({
		payload: {
			person: user ? {
				id: user.id,
				email: user.email
			} : null
		}
	});
};

export default Rollbar;
