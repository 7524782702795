import './app'
import {handleChangeUser as handleChangeUserRollbar} from './rollbar'
import {handleChangeUser as handleChangeUserGA} from './ga'
import {handleChangeUser as handleChangeUserGist} from './gist'
import {handleChangeUser as handleChangeUserTawkTo} from './tawkTo'
import './baseApi'
import './apollo'
// import './firebase'
import './routesHelper'
import './timeAgo'
import './reactScrollableAnchor'

export {setStore} from './app'

export const handleChangeStore = (store) => {
	let currentValue;
	store.subscribe(() => {
		let previousValue = currentValue;
		currentValue = store.getState().auth;
		if (previousValue !== currentValue) {
			handleChangeUserRollbar(currentValue.user);
			handleChangeUserGA(currentValue.user);
			handleChangeUserGist(currentValue.user);
			handleChangeUserTawkTo(currentValue.user);
		}
	});
};
