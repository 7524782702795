import {ApolloClient} from 'apollo-client'
import {createHttpLink} from 'apollo-link-http'
import {setContext} from 'apollo-link-context'
import {InMemoryCache} from 'apollo-cache-inmemory'
import { createPersistedQueryLink } from 'apollo-link-persisted-queries'
import App from '@prepler/core'

import {AppConfig} from '../config'
import apolloInitialState from './initialState'
import resolvers from './resolvers'
import jsonParse from '../utils/jsonParse'

const ssr = App.type === 'node';
const localStorage = !ssr && window.localStorage;
let token;
let deviceId;
let link = null;
let cache = null;
let apolloClient = null;

export const setAuthToken = (client, response) => {
	if (response) {
		const {data: {user}} = response;
		token = {...user};
	} else {
		token = null;
	}
	client.resetStore();
	return response;
};

export const clearAuthToken = (client) => {
	token = null;
	client.resetStore();
};

const authorizationHeaders = ({token, deviceId}) => {
	if (token) {
		const {access_token: accessToken, token_type: tokenType} = token;
		return (
			(accessToken && tokenType) ? {Authorization: `${tokenType} ${accessToken}`} : {}
		)
	}
	if (deviceId) {
		return {'X-Device-id': deviceId};
	}
	return {};
};

const getAuthToken = ((options) => {
	if (ssr && options && (options.token || options.deviceId)) {
		return Promise.resolve({token: options.token, deviceId: options.deviceId});
	}
	if (ssr || typeof token !== 'undefined') {
		return Promise.resolve({token, deviceId});
	}
	const authString = localStorage.getItem('reduxPersist:auth') || '{}';
	const auth = jsonParse(authString);
	token = auth ? auth.token : {};
	deviceId = auth ? auth.deviceId : undefined;
	return Promise.resolve({token, deviceId});
});

const defaultOptions = process.env.NODE_ENV === 'production' ? {} : {
	connectToDevTools: true,
	watchQuery: {
		// fetchPolicy: 'no-cache',
		// errorPolicy: 'ignore',
	},
	query: {
		// fetchPolicy: 'no-cache',
		// errorPolicy: 'all',
	},
};

export const setApolloDeviceId = (id) => {
	if (deviceId !== id) {
		deviceId = id;
	}
};

export const resetApolloStoreIfImplicit = (response) => {
	response && response.data && response.data.meta && response.data.meta.implicit && apolloClient.resetStore();
	return response;
};

export const initClient = (options) => {
	const uri = `${ssr ? AppConfig.ssrApiURL || AppConfig.apiURL : AppConfig.apiURL}/graphql`;
	const httpLink = createPersistedQueryLink({ useGETForHashedQueries: true }).concat(createHttpLink({ uri, fetch: options && options.fetch }));
	const authLink = setContext((_, {headers, authPublic}) => getAuthToken(options)
		.then(({token, deviceId}) => ({
			headers: {
				...headers,
				// authPublic - workaround to have better cache experience in cloudfront for public data.
				// authorization create cache for each user. Since it is same public data we can use one cache for all and get better experience.
				...(authPublic ? {} : authorizationHeaders({token, deviceId}))
			}
		}))
	);
	const link = authLink.concat(httpLink);
	const cache = new InMemoryCache().restore(window.__APOLLO_STATE__);
	const apolloClient = new ApolloClient({ssrMode: ssr, link, cache, resolvers, defaultOptions});
	cache.writeData({data: apolloInitialState});
	apolloClient.onResetStore(() => {
		apolloClient.writeData({data: apolloInitialState});
	});
	return [link, cache, apolloClient];
};

[link, cache, apolloClient] = initClient();

export default apolloClient;
