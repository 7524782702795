import React from 'react'
import watsonSearching from '../../assets/images/watson-searching-gray.svg'
import watsonSearchingWhite from '../../assets/images/watson-searching.svg'
// import fileAsBase64Src from '!base64-image-loader!@prepler/core/lib/resources/loader.svg'

// const Loader = () => <img alt="" src={fileAsBase64Src}/>;
const Loader = ({type, ...props}) => {
	return type === 'white' ? (
		<img alt="loader" src={watsonSearchingWhite} style={{width: '32px'}} {...props}/>
	) : (
		<center>
			<img alt="loader" src={watsonSearching} style={{width: '32px'}} {...props}/>
		</center>
	);
};

export default Loader
