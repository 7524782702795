import App from '@prepler/core'

const tawkTo = (propertyId, key) => {
	if (!window) {
		throw new Error('DOM is unavailable')
	}
	const id = 'tawkId';
	window.Tawk_API = window.Tawk_API || {};
	window.Tawk_LoadStart = new Date();
	const tawk = document.getElementById(id);
	if (tawk) {
		// Prevent TawkTo to create root script if it already exists
		return window.Tawk_API;
	}
	if (!key) {
		throw new Error('Key not provided. Get key from tawk dashboard - Direct Chat Link');
	}
	const script = document.createElement('script');
	script.id = id;
	script.async = true;
	script.src = `https://embed.tawk.to/${propertyId}/${key}`;
	script.charset = 'UTF-8';
	script.setAttribute('crossorigin', '*');
	const first_script_tag = document.getElementsByTagName('script')[0];
	if (!first_script_tag || !first_script_tag.parentNode) {
		throw new Error('DOM is unavailable');
	}
	first_script_tag.parentNode.insertBefore(script, first_script_tag);
};

const setOnLoad = (onLoad) => {
	if (App.type === 'node') return;
	window.Tawk_API = window.Tawk_API || {};
	window.Tawk_API.onLoad = onLoad;
};

if (App.type !== 'node' && process.env.REACT_APP_TAWK_TO_PROPERTY_ID && process.env.REACT_APP_TAWK_TO_KEY) { // eslint-disable-line no-undef
	tawkTo(process.env.REACT_APP_TAWK_TO_PROPERTY_ID, process.env.REACT_APP_TAWK_TO_KEY); // eslint-disable-line no-undef
}

export const handleChangeUser = (user) => {
	if (!user) return ;
	const {id, name, email} = user;
	const attributes = {id, name: name === '' ? id.toString() : name, email: email === '' ? `${id}@prepler.com` : email};
	const set = () => {
		try {
			getTawkTo().setAttributes(attributes, (error) => console.log(error));
		} catch (e) {
			console.error(e);
		}
	};
	if (!getTawkTo() || !getTawkTo().setAttributes) {
		setOnLoad(set)
	} else {
		set()
	}
};

export const getTawkTo = () => ((w) => App.type !== 'node' && w.Tawk_API)(window);
