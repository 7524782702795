import murmurhash from 'murmurhash'

export const jssGenerateId = (rule, sheet) => {
	let jssId = '';
	let prefix = '';
	if (sheet) {
		if (sheet.options.classNamePrefix) {
			prefix = sheet.options.classNamePrefix;
		}
		if (sheet.options.jss.id != null) {
			jssId = String(sheet.options.jss.id);
		}
	}
	if (process.env.NODE_ENV === 'production') {
		return `c${jssId}${murmurhash.v3(rule.key + rule.toString())}`;
	}
	return `${prefix + rule.key}${jssId ? `-${jssId}` : ''}-${murmurhash.v3(rule.toString())}`;
};

export default jssGenerateId;
