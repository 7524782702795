import Cookies from 'universal-cookie'
export const cookies = new Cookies();

export const setAuthToken = (response) => {
	if (response) {
		const {data: {user}} = response;
		cookies.set('_token', {...user});
	} else {
		clearAuthToken();
	}
	return response;
};

export const clearAuthToken = () => {
	cookies.set('_token', null);
};

export const setDeviceId = (id) => cookies.set('_device_id', id);
export const setDeviceIdExist = (value) => cookies.set('_device_id_exist', value);
