import axios from 'axios'
import App from '@prepler/core/lib/app'
import BaseApi from '@prepler/core/lib/api/baseApi'
import LearningBaseApi from '@prepler/core/lib/api/learning/baseApi'
import {setBaseUrl} from '@prepler/core/lib/api/functions'
import {AppConfig} from '../config'

const defaultCatchError = (error) => {
	if (axios.isCancel(error)) {
		error.message && App.createNotification({type: 'error', message: error.message});
		return true;
	}
	if (error.response === undefined) {
		return false;
	}
	if (error.response.status === 401) {
		App.navigateTo('/#modalUserSignIn', {error: error.response.data.error});
		return true;
	}
	if (error.response.status === 404) {
		// TODO: make more sophisticated logic when to redirect
		App.navigateTo('/404', {error: error.response.data.error});
		return true;
	}
	if (error.response.status >= 400 && error.response.status < 500) {
		let message = error.response.data.error;
		if (!error.response.data.error && error.response.data.errors) {
			const errors = error.response.data.errors;
			message = Object.keys(errors).map((key) => `${key} ${errors[key].join(', ')}`).join('\n')
		}
		App.createNotification({type: 'error', title: message});
		return true;
	}
	if (error.response.status >= 500) {
		App.createNotification({type: 'error', message: 'We are currently experiencing high server load. Please, try one more time.'});
		return true;
	}
	return false;
};

BaseApi.setDefaultCatchError(defaultCatchError);
LearningBaseApi.setDefaultCatchError(defaultCatchError);
setBaseUrl(App.type === 'node' ? AppConfig.ssrApiURL || AppConfig.apiURL : AppConfig.apiURL);
