import App from '@prepler/core'

export const initialize = (appId) => loadGist(appId);

export const initializeFake = () => {
	return {
		trackPageView: () => {},
		identify: () => {},
		track: () => {},
		setAppId: () => {},
		chat: () => {}
	}
};

export const loadGist = (appId) => {
	return (function (d, h, w) {
		let gist = w.gist = w.gist || [];
		gist.methods = ['trackPageView', 'identify', 'track', 'setAppId', 'chat'];
		gist.factory = function (t) {
			return function () {
				let e = Array.prototype.slice.call(arguments);
				e.unshift(t);
				gist.push(e);
				return gist;
			}
		};
		for (let i = 0; i < gist.methods.length; i++) {
			let c = gist.methods[i];
			gist[c] = gist.factory(c)
		}
		let s = d.createElement('script');
		s.src = 'https://widget.getgist.com';
		s.async = !0;
		let e = d.getElementsByTagName(h)[0];
		e.appendChild(s);
		s.addEventListener('load', function (e) {}, !1);
		gist.setAppId(appId);
		gist.trackPageView();
		return gist;
	})(document, 'head', window);
};

let gist = null;
if (App.type !== 'node' && process.env.REACT_APP_GIST_APP_ID) { // eslint-disable-line no-undef
	// gist = initialize(process.env.REACT_APP_GIST_APP_ID); // eslint-disable-line no-undef
	gist = initializeFake();
} else {
	gist = initializeFake();
}

export const handleChangeUser = (user) => {
	if (user) {
		gist.identify(user.id, user);
	} else {
		gist.identify(null);
	}
};

export const getGist = () => (function (w) { return w.gist; })(window) || gist;
