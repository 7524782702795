export default {
	majors: {data: [], meta: {}},
	courses: {},
	goals: {data: [], shared: {data: []}},
	periods: {data: [], meta: {}},
	subtitutes: {},
	goalAccesses: {data: [], meta: {}},
	studyYears: {data: [], meta: {}},
	years: {data: [], meta: {}},
}
