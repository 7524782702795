import adminInitialState from './admin/initialState'
import learningInitialState from './learning/initialState'

export default {
	rehydrate: false,
	entities: {},
	admin: adminInitialState,
	learning: learningInitialState,
	auth: {
		token: null,
		user: null,
		deviceId: null,
		implicit: false,
		// firebase: null,
		authorized: null,
		authorizing: null
	},
	deviceStorage: {},
	profile: {},
	notifications: [],
	// socialAuths: [],
	locations: {},
	search: {
		filters: {
			// Filters are now in query string
			lon: null,
			lat: null,
			sort_by: 'relevance'
		},
		locations: {meta: {}},
		majors: {meta: {}},
		universities: {meta: {}},
		career: {meta: {}},
		collections: {meta: {}}
	},
	// searchForm: {
	// 	query: '',
	// 	title: '',
	// 	suggestions: {
	// 		schools: [],
	// 		locations: [],
	// 		majors: [],
	// 		careers: [],
	// 	},
	// },
	universities: {
		web: {universities: [], meta: {}},
		favorite: {universities: [], meta: {}},
		search: {universities: [], meta: {}},
		vote: {universities: [], meta: {}},
		url: null,
		feeds: {
			facebook: {},
			twitter: {},
			instagram: {},
			youtube: {}
		},
		filters: {
			lon: null,
			lat: null,
			sort_by: 'relevance'
		}
	},
	users: {data: [], meta: {}},
	collections: {
		random: null,
		byName: {}
	},
	// policyTerms: {byName: {}},
	roles: {data: [], meta: {}},
	// chats: {
	// 	data: {}, chats: [], meta: {}, newMessagesCount: {},
	// 	ofUser: {chats: [], meta: {}}
	// },
	// messages: {data: {}, messages: [], meta: {}},
	// articles: {
	// 	default: {data: [], meta: {}},
	// 	popular: {data: [], meta: {}}
	// },
	tariffModels: {data: [], meta: {}},
	reviews: {data: [], meta: {}}
}
