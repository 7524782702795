const theme = {
	point: {
		sm: '768px',
		md: '992px',
		lg: '1200px'
	},
	container: {
		sm: '750px',
		md: '970px',
		lg: '1170px'
	},
	dialogWidth: '600px',
	headerHeight: '63px',
	gutter: {
		base: '15px',
		half: '8px',
		small: '5px',
		vertical: '12px',
		verticalHalf: '6px'
	},
	button: {
		base: '32px',
		social: '45px'
	},
	fonts: {
		base: 'normal 14px/22px Montserrat, Helvetica, Arial, sans-serif',
		brand: 'Montserrat, Helvetica, Arial, sans-serif',
	},
	fontWeight: {
		// Standard gradation
		// thin: '100',
		// ulight: '200',
		// light: '300',
		// normal: '400',
		// medium: '500',
		// sbold: '600',
		// bold: '700',
		// ebold: '800',
		// heavy: '900'
		// We use only 3
		light: '300',
		normal: '500',
		bold: '700'
	},
	border: {
		radiusBox: '8px',
		radiusButton: '4px'
	},
	colors: {
		background: '#fff',
		backgroundGray: '#f8f8f8',
		white: '#fff',
		black: '#000',
		primary: '#3e8bca',
		success: '#8bc24a',
		warning: 'yellow',
		danger: 'red',
		error: 'red',
		blue: [
			'#3e8bca', // 0
			'#97caf4', // 1
		],
		darkBlue: '#242e3b',
		mediumBlue: '#123456',
		mediumBlueOpacity: '#0f3759cc',
		green: [
			'#87d37c', // 0
			'#89c34a' // 1
		],
		red: [
			'red', // 0
			'#ea4335', // 1
			'#f9504c', // 2
		],
		button: '#1fa0e7',
		facebook: '#3b5998',
		google: '#ea4335',
		borderGray: '#c6cad0',
		gray: [
			'#f6f6f6', // 0
			'#e3e3e3', // 1
			'#cccccc', // 2
			'#aaaaaa', // 3
			'#999999', // 4
			'#666666', // 5
			'#333333', // 6
			'#222222', // 7
			'#111111' // 8
		]
	}
};

export default theme
