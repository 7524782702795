/* eslint-disable no-undef */
export const AppConfig = {
	facebook: {
		app_id: process.env.REACT_APP_FACEBOOK_APP_ID
	},
	google: {
		app_id: process.env.REACT_APP_GOOGLE_APP_ID
	},
	linkedin: {
		app_id: process.env.REACT_APP_LINKEDIN_APP_ID
	},
	apiURL: process.env.REACT_APP_API_URL,
	ssrApiURL: process.env.REACT_APP_SSR_API_URL
};

// export const FirebaseConfig = {
// 	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
// 	accessToken: process.env.REACT_APP_FIREBASE_ACCESS_TOKEN,
// 	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || 'fir-prepler.firebaseapp.com',
// 	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL || 'https://fir-prepler.firebaseio.com',
// 	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || 'firebase-prepler',
// 	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || 'firebase-prepler.appspot.com',
// 	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
// };

export const RollbarConfig = {
	accessToken: process.env.REACT_APP_ROLLBAR_CLIENT_ACCESS_TOKEN
};
/* eslint-enable no-undef */

export default {
	app: AppConfig,
	// firebase: FirebaseConfig,
	rollbar: RollbarConfig
}
